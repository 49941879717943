<template>

  <div>

    <div
      v-if="readOnly"
    >
      <!-- Default of 0 is for null amounts. (When funds are added to 0 items) -->
      <!-- If item has an updatedPayable show that amount until they either
      update, or remove the item -->
      $ {{ displayFormat(parseNumber(cartItem.updatedPayable ? cartItem.updatedPayable.amount : (cartItem.amount || 0)) * (parseNumber(cartItem.quantity) || 1)) }}
      <!-- Theoretically there will never be quantity modifiable items
      which are amount modifiable as well -->
      <div class="subtext position-absolute">
        {{ quantityReference }}
        {{ amountReference }}
      </div>
    </div>
    <div v-else>
      <b-input-group
        class="ml-2 my-2 py-0 pl-0 align-items-center"
      >
        <b-form-input
          ref="amountInput"
          v-model="internalAmount"
          :readonly="$wait.waiting(`modifying.${cartItem.id}`)"
          :type="isFirefox ? 'text' : 'number'"
          class="amount ml-1 mr-0 text-right"
          :min="cartItem.payable.minAmount"
          :max="cartItem.payable.maxAmount"
          :aria-label="$t('amount.label')"
          step="0.01"
          :state="!v$.$error"
          data-test="amount-input"
          @focus="selectValue"
          @change="updateAmount"
        />
        <ValidationErrors
          class="ml-3 right-error"
          :validator="v$.internalAmount"
          :errors="{
            required: $t('amount.error.required'),
            currency: $t('amount.error.currency'),
            minValue: $t('amount.error.min_value', { min: cartItem.payable.minAmount }),
            maxValue: $t('amount.error.max_value', { max: cartItem.payable.maxAmount }),
          }"
        />
      </b-input-group>
      <div
        v-show="showOriginalAmount"
        class="subtext low position-absolute"
      >
        {{ $t('account.has_credit.default', { amount: cartItem.payable.absoluteDisplayAmount }) }}
      </div>
      <div
        v-show="showAbsoluteAmount"
        class="subtext low position-absolute"
      >
        {{ $t('original_amount', { amount: cartItem.payable.absoluteDisplayAmount }) }}
      </div>
    </div>

  </div>
</template>

<script>
import ValidationErrors from '@grantstreet/psc-vue/components/ValidationErrors.vue'
import { decimalFormat, displayFormat, parseNumber } from '@grantstreet/psc-js/utils/numbers.js'
import { useVuelidate } from '@vuelidate/core'
import { currency } from '@grantstreet/psc-js/utils/validators.js'
import { required } from '@vuelidate/validators'

export default {
  emits: ['amount-update', 'update-error'],
  components: {
    ValidationErrors,
  },

  setup () {
    return {
      v$: useVuelidate(),
    }
  },

  props: {
    cartItem: {
      type: Object,
      default: () => ({}),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      internalAmount: decimalFormat(this.cartItem.amount || 0),
      isFirefox: /firefox|iceweasel|fxios/i.test(window.navigator.userAgent),
      backupAmount: decimalFormat(this.cartItem.amount || 0),
    }
  },

  computed: {
    onReceipt () {
      return this.$router.currentRoute.value.name === 'receipt'
    },

    quantityReference () {
      const { quantity, amount } = this.cartItem
      if (quantity && quantity > 1) {
        return `($ ${displayFormat(amount)} x ${quantity})`
      }
      return ''
    },

    amountReference () {
      const { payable } = this.cartItem
      if (!this.showOriginalAmount || this.onReceipt || !payable.hasAmount) {
        return ''
      }
      return this.$t('account.has_credit.default', {
        amount: payable.absoluteDisplayAmount,
      })
    },

    showOriginalAmount () {
      const payable = this.cartItem.payable
      return this.internalAmount !== payable.amount &&
      payable.hasAmount &&
      payable.amount <= 0
    },

    showAbsoluteAmount () {
      const payable = this.cartItem.payable
      return this.internalAmount !== payable.absoluteDisplayAmount
    },
  },

  validations: {
    internalAmount: {
      required,
      currency,
      minValue: function (value) {
        const min = this.cartItem.payable?.minAmount || 0.01
        return !value || parseNumber(value) >= min
      },
      // we can't use maxValue from vuelidate here because we have to pass props
      // before they're available
      maxValue: function (value) {
        // Should we be handling for 0 here? Is this a string or number?
        const max = this.cartItem.payable?.maxAmount || Number.MAX_SAFE_INTEGER
        return !value || parseNumber(value) <= max
      },
    },
  },

  methods: {
    displayFormat,
    parseNumber,

    selectValue (event) {
      if (this.internalAmount === decimalFormat(0.00)) {
        event.target.select()
      }
    },

    async updateAmount (amount) {
      this.$wait.start(`modifying.${this.cartItem.id}`)
      this.v$.$touch()
      if (this.v$.$invalid) {
        this.$wait.end(`modifying.${this.cartItem.id}`)
        return
      }
      // I think this decimalFormat is unnecessary...
      this.internalAmount = decimalFormat(this.internalAmount)

      try {
        await this.$store.dispatch('Cart/modifyItemAmount', {
          item: this.cartItem,
          amount: this.internalAmount,
        })
        this.$emit('amount-update')
        this.$emit('update-error', false)
        this.backupAmount = this.internalAmount
      }
      catch (error) {
        this.$emit('update-error', true)
        this.internalAmount = this.backupAmount
      }
      this.$wait.end(`modifying.${this.cartItem.id}`)
    },

    validate () {
      this.v$.$touch()
      return !this.v$.$invalid
    },
  },

  watch: {
    cartItem: function (newVal) {
      this.internalAmount = decimalFormat(newVal.amount || 0)
      this.backupAmount = decimalFormat(newVal.amount || 0)
    },
  },
}
</script>

<style lang="scss" scoped>
.invalid-tooltip {
  left: auto;
}

// Padding value are grabbed from gsg-bootstrap.css.
// !important is needed sadly to override Bootstrap's version.
.was-validated .form-control:valid, .form-control.is-valid {
  padding: 0.375rem 0.75rem !important;
}
.amount {
  max-width: 5em;
  border-radius: 5px !important;
}

.right-error {
  max-width: none;
  right: 0.5em;
}

.subtext {
  right: map-get($spacers, 3);
  bottom: -1.1em;
  font-size: 1rem;
}

</style>
