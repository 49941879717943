import VueDOMPurifyHTML from 'vue-dompurify-html'
import sanitizeConfig from '@grantstreet/psc-js/utils/sanitize.js'

import { createApp, h } from 'vue'
import { createVueWait } from 'vue-wait'
import LoadFailure from './views/LoadFailure.vue'
import store from './store/index.js'
import initBootstrapVue from '@grantstreet/psc-vue/utils/init-vue-bootstrap.js'
import VueSVGIcon from '@grantstreet/bootstrap/icons/vue-svgicon.js'
import '@grantstreet/bootstrap/icons/js/index.js'
import VueGtag, { event as logAnalyticsEvent } from 'vue-gtag'
import { createHead } from '@unhead/vue'
import { sentryException, vueErrorHandler } from './sentry.js'
import { initRouter } from './router.js'
import EventBus from '@grantstreet/psc-vue/utils/event-bus.js'
import App from './components/App.vue'
import { installModule } from '@grantstreet/psc-vue/utils/install-utils.js'

// PSP widgets
import eWallet from '@grantstreet/e-wallet-vue'
import { initializeEWallet } from './e-wallet-helpers.js'
import MyItems from '@grantstreet/my-things-vue'
import MyPayments from '@grantstreet/my-payments-vue'
import Announcements from '@grantstreet/announcements-vue'
import Help from '@grantstreet/help-vue'
import Cart from '@grantstreet/cart-vue'
import SchedPay from '@grantstreet/schedpay-vue'
import { installEBillingPublic } from '@grantstreet/e-billing-public'
import { installUserVerification } from '@grantstreet/user-verification-public'
import { installDeliveryMethod } from '@grantstreet/delivery-method'
import { getPayableSource, installPayables, searchPayablesPath } from '@grantstreet/payables'
import { installFormsPublic } from '@grantstreet/forms-public-legacy'
import { installDonations } from '@grantstreet/donations'
import installIndexSearch from '@grantstreet/index-search'
import installRouterSync from '@grantstreet/router-sync'
import { installConfig, getClientsAndSites, loadConfig, configState, configGetters, setLDMetadata } from '@grantstreet/psc-config'

import {
  installExternalLogin,
  installFullLogin,
  handleLoginCallback,
  user,
} from '@grantstreet/login'

import './styles/index.js'

// APIs
import EnvironmentApi from '@grantstreet/psc-environment-api/api-client.js'
import AnnouncementsApi from '@grantstreet/announcements-vue/src/api-client.js'
import CartApi from '@grantstreet/cart-vue/src/api-client.js'
import ContactApi from '@grantstreet/help-vue/src/api-client.js'
import MyPaymentsApi from '@grantstreet/my-payments-vue/src/api-client.js'
import MyItemsApi from '@grantstreet/my-things-vue/src/api-client.js'
import LoginApi from '@grantstreet/login-api'
import RequestApi from '@grantstreet/request-api'
import SchedPayApi from '@grantstreet/schedpay-vue/src/api-client.js'

import type { GoogleAnalytics } from './embedded-demo/EmbeddedDemoUtils.js'
import { loadTranslations, i18n } from '@grantstreet/psc-vue/utils/i18n.ts'
import { setEnvironment, setLocalTaxSysSandboxUrl, setRootTaxSysIframeUrl } from '@grantstreet/psc-environment/environment.js'
import { getPayHubGaId } from '@grantstreet/psc-vue/utils/google-analytics.js'
import iframeWhisperer from '@grantstreet/iframe-whisperer'
import { NavigationFailureType } from 'vue-router'
import User from '@grantstreet/login/src/models/User.ts'
import VueSignaturePad from 'vue-signature-pad'

import { plugin as formKitPlugin } from '@formkit/vue'
import createFormKitConfig from '../formkit.config.js'

declare let window: Window & {
  __VUE_DEVTOOLS_GLOBAL_HOOK__
}

type embeddedAttachPayload = {
  googleAnalytics: GoogleAnalytics
  language: string
  localTaxSysUrl: string
  rootTaxSysIframeUrl: string
}
let embeddedParentWhisperer
// this embeddedAttachPromise will be resolved by the embeddedParentWhisperer when
// the attach message is received.
let resolveEmbeddedAttachPromise: (value: embeddedAttachPayload) => void
const embeddedAttachPromise: Promise<embeddedAttachPayload> = new Promise((resolve) => {
  resolveEmbeddedAttachPromise = resolve
})

// Do not add blocking requests to this method. Everything should be loaded
// asynchronously so that the page skeleton can be rendered ASAP.
export default async function install (Vue) {
  try {
    // Init translations, and bootstrap first thing so we can show a localized
    // error page if anything goes wrong.
    // Is there any reason not to move this into the installing application?
    // (Sentry is already initialized.)

    initI18n()

    initBootstrapVue(Vue)

    // Create an iframe whisperer. If this is a "full" govhub installation, this
    // whisperer will not send or receive any messages.
    // If this is an "embedded" govhub installation, this whisperer will be used
    // to communicate with the parent wrapper script.
    // see @grantstreet/govhub-ui/src/main-embedded-wrapper.ts
    // This parent whisperer must be created before the iframe load event is
    // fired, otherwise the wrapper attach message will get dropped.
    embeddedParentWhisperer = new iframeWhisperer.ToParent({
      // autoHeight must be false since this is a standalone whisperer for full
      // govhub installations
      autoHeight: false,
      messageSource: 'embedded-public-site',
      actions: {
        // The attach message is sent from the wrapper script. Currently, it
        // does nothing. It will include some required information such as
        // google analytics information in the future
        'embeddedPublicSite.attach': (payload: embeddedAttachPayload) => {
          resolveEmbeddedAttachPromise(payload)
        },
      },
    })

    await initializeEnvironment(EnvironmentApi)

    // Must be set for diagnostics logging to work
    store.commit('API/setRequestApi', RequestApi)

    installConfig({
      logDiagnostics: data => {
        store.dispatch('PayHub/logDiagnostics', data)
      },
    })

    // Before anything else, check if we're on a login callback URL (e.g.,
    // govhub.com/callback), and resolve that if so. This is done before
    // everything because the callback "site" isn't a real client-site that can
    // be installed.
    await handleLoginCallback('payhub-spa')

    store.commit('API/setLoginApi', LoginApi)

    Vue.use(VueDOMPurifyHTML, sanitizeConfig)
    Vue.use(VueSVGIcon, { isStroke: true })
    Vue.use(VueSignaturePad)

    const { client, site } = clientAndSiteFromUrl()

    // LD flags can be used to enable a few basic announcements
    // Set this listener before the configLoading process starts
    EventBus.$on('config.flagsChanged', async flags => {
      // setAnnouncement depends on the config
      store.dispatch('PayHub/setAnnouncement', { name: flags.announcement })
    })

    await getClientsAndSites()
    await loadConfig({ client, site })
    const embeddedMode = configGetters?.useEmbeddedPublicSite

    await (embeddedMode ? installEmbedded : installFull)(Vue)

    // Indicate success
    return true
  }
  catch (error) {
    console.error(error)
    sentryException(error as Error)
    // eslint-disable-next-line vue/one-component-per-file
    createApp({
      render: () => h(LoadFailure),
    })
      .use(vueErrorHandler)
      .use(i18n)
      .mount('#app')

    logBootTime()
  }
}

/**
 * installEmbedded installs govhub with a bunch of the optional modules
 * disabled. This is the 'embedded' mode.
 * @see {@link ../README.md} for more information on the embedded public site
 */
async function installEmbedded (Vue) {
  // verify that this exists within an iframe
  // This value is passed to the installShared function. Once the vue router has
  // been constructed, if this evaluates to true, the user will be redirected to
  // the load-failure page
  if (window.self === window.top) {
    throw new Error('The embedded public site can only be installed in an iframe. No iframe was detected.')
  }

  // Start handling height changes to prevent a double scrollbar in the iframe
  // containing the public site.
  embeddedParentWhisperer.startHandlingHeight()

  // Before the embedded public site can be installed, the embedded wrapping
  // script must whisper an 'attach' message that includes google analytics
  // data in its payload. This message should already have been sent by this
  // point. The installer will wait a maximum of 10 seconds before giving up
  // and mounting the load failure page.
  const attachTimeout = new Promise((_resolve, reject) => {
    setTimeout(() => {
      reject(new Error('For govhub to install as an embedded public site, the installer must receive an attach event from the embedded wrapper script. The installer did not receive any events after 30 seconds.'))
    }, 30_000)
  })
  const { googleAnalytics, localTaxSysUrl, language, rootTaxSysIframeUrl } = await Promise.race([embeddedAttachPromise, attachTimeout]) as embeddedAttachPayload
  setLocalTaxSysSandboxUrl(localTaxSysUrl)
  setRootTaxSysIframeUrl(rootTaxSysIframeUrl)

  // set the language. This will be overriden by the installShared function
  // if a user is logged in - with their last used language.
  store.dispatch('PayHub/setLocale', {
    locale: language,
    $i18n: i18n,
    updateUser: false,
  })

  // The router will emit this event after every route change. The new path must
  // then be whisperered to the attach script, which will provide the new path
  // to the embedding parent site.
  EventBus.$on('routeChange', newRoute => {
    embeddedParentWhisperer.notify({
      action: 'routeChange',
      payload: newRoute.fullPath,
    })
  })

  // Install govhub with limited modules
  return installShared(Vue, {
    enableHeader: false,
    enableFooter: false,
    showAnnouncements: false,
    enableMyForms: false,
    enableHomePage: false,
    enableFloatingCart: false,
    ...googleAnalytics,
    enableFullLogin: false,
    enableMySettingsModification: false,
    handleLogin: () => {},
  })
}

/**
 * installFull simply passes the parameters received in the install function
 * through to the installShared function, with all the modules enabled. This is
 * the 'full' govhub mode.
 */
const installFull = (Vue) => {
  // destroy the standalone parent whisperer that is not needed
  embeddedParentWhisperer.destroy()
  // Set up linking to a TaxSys sandbox, if needed
  if (process.env?.GSG_TAX_CBS_SERVICE) {
    setRootTaxSysIframeUrl(process.env.GSG_TAX_CBS_SERVICE)
  }
  // proceed with installation
  installShared(Vue, {})
}

/**
 * installShared finishes the public site installation.
 * @param Vue
 * @param installParams - installation data to configure the public site installation.
 */
async function installShared (Vue, {
  enableHeader = true,
  enableFooter = true,
  showAnnouncements = true,
  enableMyForms = true,
  enableHomePage = true,
  enableFloatingCart = true,
  googleTagId = '',
  userId = '',
  enableFullLogin = true,
  enableMySettingsModification = true,
  // @ts-expect-error This will be implemented by the EPS project
  handleLogin = () => {},
}: {
  enableHeader?: boolean
  enableFooter?: boolean
  enableMyForms?: boolean
  enableHomePage?: boolean
  enableFloatingCart?: boolean
  enableFullLogin?: boolean
  enableMySettingsModification?: boolean
  showAnnouncements?: boolean
  googleTagId?: string
  userId?: string
  handleLogin?: () => void
}) {
  // Store install flags in the payhub store
  store.dispatch('PayHub/setInstallFlags', {
    enableHeader,
    enableFooter,
    showAnnouncements,
    enableMyForms,
    enableHomePage,
    enableFloatingCart,
    enableMySettingsModification,
  })

  const { client, site } = clientAndSiteFromUrl()
  const { useEbilling, useUserVerification, useDelivery, useForms, useLogin } = configGetters
  const config = configState.config

  // Can't show the app until the router is loaded.
  const router = await initRouter()

  // Init google analytics (before eWallet or anywhere else)
  if (Vue.$gtag) {
    sentryException(new Error('Vue Google Analytics already initialized in PayHub'))
  }

  // Install PSC widgets
  // TODO: Switch to using @grantstreet/psc-vue/utils/install-utils
  let loginManager
  if (enableFullLogin) {
    loginManager = installFullLogin({
      oidcClient: 'payhub-spa',
      bus: EventBus,
      useLogin: () => configGetters.useLogin,
      getClientInfo: () => {
        const {
          client,
          site,
          payHub: {
            clientTitle: clientDisplay = '',
            landingPageTitle: siteDisplay = '',
            clientLogo = '',
          } = {},
        } = config

        return {
          client,
          site,
          clientDisplay,
          siteDisplay,
          clientLogo,
        }
      },
      logAnalyticsEvent,
    })
  }
  else {
    loginManager = installExternalLogin({
      bus: EventBus,
      getExternalJwt: async () => {
        return await embeddedParentWhisperer.message({
          action: 'gsgPublicSite.getJwt',
        })
      },
      handleLogin: () => {
        embeddedParentWhisperer.notify({
          action: 'gsgPublicSite.handleLogin',
        })
      },
    })
  }

  store.commit('PayHub/setAuthPromise', loginManager.authPromise)

  installModule(
    installRouterSync,
    {
      store,
      router,
    },
    'Router Sync',
  )

  if (showAnnouncements) {
    Vue.use(Announcements, {
      store,
      api: AnnouncementsApi,
      bus: EventBus,
    })
  }

  Vue.use(MyPayments, {
    store,
    api: MyPaymentsApi,
    sentryException,
  })

  Vue.use(MyItems, {
    store,
    router,
    api: MyItemsApi,
    bus: EventBus,
    sentryException,
  })

  Vue.use(Help, {
    store,
    api: ContactApi,
    bus: EventBus,
    sentryException,
  })

  Vue.use(SchedPay, {
    store,
    api: SchedPayApi,
    bus: EventBus,
  })

  installModule(
    installIndexSearch,
    {
      store,
    },
    'Index Search',
  )

  installPayables({
    payablesConfig: {
      client: config.client,
      site: config.site,
      payableSources: config.payableSources?.payableSources || [],
      contactCountyText: config.renewexpress?.contactCountyText,
      useDelivery: Boolean(config.delivery?.meta?.enabled),
      enableRExHubPickUp: Boolean(config.delivery?.enableRExHubPickUp),
      renewalServiceFee: config.renewexpress?.renewalServiceFee,
      extendVehicleRegistrationSearch: config.renewexpress?.extendVehicleRegistrationSearch,
      useInlineInsuranceAffidavits: config.renewexpress?.useInlineInsuranceAffidavits,
      insuranceCommercialVehicleHelp: config.renewexpress?.insuranceCommercialVehicleHelp,
      insuranceMilitaryPersonnelHelp: config.renewexpress?.insuranceMilitaryPersonnelHelp,
      insuranceVIN: config.renewexpress?.insuranceVIN,
      insuranceSignature: config.renewexpress?.insuranceSignature,
    },
    eventBus: EventBus,
    logDiagnostics: data => {
      store.dispatch('PayHub/logDiagnostics', data)
    },
  })

  if (useEbilling) {
    installEBillingPublic({
      user,
      client: config.client,
      site: config.site,
      logRequest: (...args) => {
        store.dispatch('PayHub/logRequest', ...args)
      },
    })
  }

  if (useUserVerification) {
    installUserVerification({
      user,
      client: config.client,
      site: config.site,
      logRequest: (...args) => store.dispatch('PayHub/logRequest', ...args),
    })
  }

  if (useDelivery) {
    installDeliveryMethod({
      user,
      client: config.client,
      site: config.site,

      enableRExHubPickUp: Boolean(config.delivery?.enableRExHubPickUp),
      usesRenewExpress: Boolean(config.renewexpress?.meta?.enabled),
      disableCustomAddress: Boolean(config.delivery?.disableCustomAddress),
      enablePermanentAddressChange:
        Boolean(config.delivery?.enableRExHubPermanentAddressChange),
      additionalRExHubPickUpFields:
        config.delivery?.additionalRExHubPickUpFields || [],
      pickupInstructions: config.delivery?.pickupInstructions,
      requireClickToShowAddress: Boolean(config.delivery?.requireClickToShowAddress),
      contactPhone: getPayableSource('rex-vehicle-registration')?.contactPhone,
    })
  }

  if (useForms) {
    installFormsPublic({
      formConfig: config.forms,
      user,
    })
  }

  if (config.renewexpress?.enableCharitableDonations) {
    installDonations({
      client: config.client,
      site: config.site,
      charitableDonations: config.renewexpress?.charitableDonations,
    })
  }

  // Check feature flag
  // This may redirect to a legacy site
  if (!checkPayHubFeatureFlag({ client, site })) {
    return false
  }

  EventBus.$on('login.userLoaded', async (user: User) => {
    if (user.language) {
      store.dispatch('PayHub/setLocale', {
        locale: user.language,
        updateUser: false,
      })
    }

    // Used for LG flag loading
    setLDMetadata(user)
  })

  // "Global" PayHub callbackActions can go here. This one is
  // associated with MSI, but since it is concerned with navigation,
  // it seems to make more sense to live with the "main app" in
  // @grantstreet/govhub-vue.
  EventBus.$on('login.callbackAction', async ({ user, action }) => {
    if (!user || action.type !== 'savePayable') {
      return
    }

    const path = action.payable
    // Search payable paths can throw an exception, but since this is being
    // called in the callback action, we can reasonably expect that the payable
    // should exist. If we don't receive the payable, we can't add it to MSI
    // anyway.
    const payable = await searchPayablesPath({ path })

    if (payable) {
      try {
        // Loading the user's saved items can fail if the request times out, or
        // if the payable paths saved are no longer valid. This will result in a
        // thrown exception, that we should catch. This will ensure that the
        // new payable is successfully saved to MSI
        await store.getters['MyItems/loadPromise']
      }
      catch (error) {
        // We expect to hit these exceptions while SBC is undergoing data
        // migration. There would be no actionable response to this error, so we
        // will silently swallow this error
      }
      await store.dispatch('MyItems/addToMyItems', { payable })

      // Note: triggers an uncaught exception in vue-router, when it tries to
      // invoke our "beforeEach" guard. This doesn't appear to prevent "replace"
      // from doing what it's supposed to do. This occurs because the
      // beforeEach guard modifies the resulting destination url, helpfulling
      // filling in the missing 'site' parameter (due to the order of route
      // matching). Since the end url doesn't match the expected end url,
      // vue-router errors.
      router.replace({ name: 'my-dashboard' }).catch((error) => {
        // @ts-expect-error This is broken but I haven't had a chance to fix
        // yet. Silencing TS so I can push for now
        if (error?.type !== NavigationFailureType.redirected) {
          // If this navigation error is not a redirection error, re-throw
          throw error
        }
      })
      return
    }

    sentryException(new Error(`Couldn't find payable for 'savePayable' event. Path was: ${path}`))
  })

  // Store all login callback actions so we can pass them around as data
  // instead of having to listen for events everywhere.
  EventBus.$on('login.callbackActions', async (actions) => {
    store.commit('PayHub/setLoginCallbackActions', actions)
  })

  EventBus.$on('ewallet.authProbeFailed', () => {
    router.push({ name: 'networkError' })
  })

  const head = createHead()

  // eslint-disable-next-line vue/one-component-per-file
  const app = createApp({
    render: createElement => createElement(App),
  })
    .use(vueErrorHandler)
    .use(store)
    .use(i18n)
    .use(router)
    .use(head)
    .use(
      VueGtag,
      getGoogleAnalyticsConfig({ googleTagId, userId }),
      router,
    )
    .use(formKitPlugin, createFormKitConfig(i18n.global.locale))

  // We need an app in order to initialize vue-wait for use in the cart
  // install. Afterwards we can continue setting up the app.
  const wait = createVueWait({ useVuex: true })
  await wait.install(app)
  const redirectCartId = await cartIdFromRedirectUrl(store)

  Vue.use(Cart, {
    store,
    wait: wait.instance,
    cartId: redirectCartId,
    api: CartApi,
    bus: EventBus,
  })

  Vue.use(eWallet, {
    store,
    bus: EventBus,
    supportsLogin: useLogin,
    initializeModule: initializeEWallet,
  })

  // Must be done after cart is installed because the helpers use the Cart store
  store.dispatch('eWallet/initialize')

  app
    .use(wait)
    .mount('#app')

  logBootTime()

  EventBus.$emit('payhub.loaded')

  // If we've been redirect to a payhub page after login, jump to that page
  const route = loginManager.getPostLoginRedirect()
  if (route) {
    router.replace({
      name: route,
      params: {
        client,
        site,
      },
    })
  }

  // If we've been redirected to a pre-filled cart, jump to
  // the checkout page
  if (redirectCartId) {
    // Per the docs on clientAndSiteFromUrl(), `site` is sometimes a bogus
    // value: for instance, for redirects on client-only sites, it's
    // `redirect`. Rather than adding special handing to the router for this
    // case, simply don't pass the bogus site.
    const params: {
      isRedirect: boolean
      client: string | undefined
      site?: string
    } = {
      isRedirect: true,
      client,
    }

    // Do pass the site on redirect if it is real though
    if (!(config.useClientOnlyUrl && config.site !== site)) {
      params.site = site
    }

    router.replace({
      name: 'checkout',
      params,
    })
  }
}

function logBootTime () {
  // Log how long it took to install GovHub (window.performance.now() gives the
  // number of milliseconds since timeOrigin, which is when page load started)
  if (window.performance?.now) {
    store.dispatch('PayHub/logDiagnostics', {
      event: 'timing-install-finished',
      duration: window.performance.now(),
    })
  }
}

function checkPayHubFeatureFlag ({ client, site }) {
  const flags = configState.flags
  const config = configState.config

  const key = `use-payhub.${client}-${site}`

  // Use PayHub if the flag doesn't exist, or if it's true
  if (typeof flags[key] === 'undefined' || flags[key]) {
    return true
  }

  // Otherwise, attempt redirect to the legacy site.
  if (config.payHub.legacySite) {
    // Note that this is a static URL. This approach only
    // works for Account Lookup sites. For redirect sites,
    // the cart (or cart redirect) microservice checks the
    // feature flag and redirects to the old site.
    window.location = config.payHub.legacySite
    return false
  }
  else {
    // There's no place to send them. This is some type of
    // misconfiguration, so we want it to show up in Sentry.
    const message = `No legacy site found for: ${client}/${site}`
    sentryException(new Error(message))
    console.error(message)

    // And then we have to use PayHub anyway.
    return true
  }
}

async function initializeEnvironment (EnvironmentApi) {
  const response = await (new EnvironmentApi({ exceptionLogger: sentryException })).getEnvironment()
  setEnvironment(response?.data?.environment)
}

// Sets the user's language and loads PayHub's translations. If the user already
// has a locale saved in localStorage this uses that. If the user doesn't have
// a locale saved it will attempt to use the browsers default languages
function initI18n () {
  let defaultLocale
  try {
    if (window.localStorage.getItem('payhubDefaultLocale')) {
      defaultLocale = window.localStorage.getItem('payhubDefaultLocale')
    }
    else {
      // Loop through browsers languages but default to en
      defaultLocale = window.navigator.languages.find(lang => {
        const abb = lang.slice(0, 2)
        return abb === 'es' || abb === 'en'
      })?.slice(0, 2) || 'en'
    }
  }
  catch (error) {
    console.error('Cannot access local storage due to incognito window')
  }
  store.commit('PayHub/setLocaleLight', defaultLocale)
  if (defaultLocale) {
    i18n.global.locale.value = defaultLocale
  }
  loadTranslations(sentryException)
}

// Parses the cartId from the URL. We can't use vue-router for this
// because it is not fully loaded until the app is mounted.
async function cartIdFromRedirectUrl (store) {
  // We await the auth promise incase we're in the process of sending a newly
  // signed up user to a redirect from their confirmation email
  try {
    await store.getters['PayHub/authPromise']
  }
  catch (error) {}
  const urlForRedirectCartId = window.history.state.redirectCartId || window.location.pathname
  // Pull id from /:client/:site/redirect/:id or /:client/redirect/:id
  const match = urlForRedirectCartId.match('^/[^/]+(?:/[^/]+)?/redirect/([^/]+)')
  return match ? match[1] : undefined
}

// Parses the client and site from the URL. We can't use vue-router for this
// because it is not fully loaded until the app is mounted. The `site` returned
// might be undefined or not actually a site if this is a client-only-url site
// (e.g., /sacramento or /sacramento/utilities-search).
function clientAndSiteFromUrl () {
  let path: string | null = window.location.pathname

  if (path === '/') {
    // Try to get to the last-used client/site
    try {
      path = window.localStorage.getItem('lastPayHubPath')
    }
    catch (error) {
      console.error('Cannot access local storage due to incognito window')
    }
    if (path) {
      window.history.replaceState(null, '', path)
    }
  }

  const parts = window.location.pathname.split('/')
  if (parts.length < 2) {
    return {}
  }

  try {
    if (path) {
      window.localStorage.setItem('lastPayHubPath', path)
    }
  }
  catch (error) {
    console.error('Cannot access local storage due to incognito window')
  }

  // Keep in sync with @grantstreet/psc-js/utils/routing.js>formatParams()
  return {
    client: (parts[1] || '').toLowerCase(),
    site: (parts[2] || '').toLowerCase(),
  }
}

function getGoogleAnalyticsConfig ({ googleTagId, userId }: GoogleAnalytics) {
  type GaTag = {
    id: string
    params?: {
      user_id: string
    }
  }
  type GaConfig = {
    config: GaTag
    includes?: GaTag[]
    disableScriptLoad: boolean
  }

  const config: GaConfig = {
    config: { id: getPayHubGaId() },
    disableScriptLoad: process.env.NODE_ENV === 'development',
  }

  // Log to secondary google analytics account if a tag is received
  if (googleTagId) {
    config.includes = [{
      id: googleTagId,
    }]

    // Log all GA events using the provided userId
    if (userId) {
      config.includes[0].params = {
        'user_id': userId,
      }
      config.config.params = {
        'user_id': userId,
      }
    }
  }

  return config
}
