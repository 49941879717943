import {
  user,
  type Bus,
  type LoginManager,
  type ExternalModeInstallOptions,
  type AuthPromise,
  type CallbackActionType,
  type LoginFunction,
  type LoginFunctionParams,
  type LogoutFunction,
  type GetPostLoginRedirectFunction,
} from '../install.ts'
import jwtDecode from 'jwt-decode'
import LoginApi from '@grantstreet/login-api'
import { sentryException } from '../sentry.js'
import storage from '@grantstreet/psc-js/utils/safe-local-storage.js'

type Auth0Jwt = {
  iss: string
  email: string
  phone?: string
  family_name?: string
  given_name?: string
  name?: string
  locale?: 'en' | 'es'
}

type LoginJwt = {
  sub: string
}

const cachedExternalJwt: string | undefined = ''

export async function getJwt (
  getExternalJwt : () => Promise<string|null|undefined>,
  updateUser: (decodedExternalJwt : Auth0Jwt, decodedLoginJwt: LoginJwt) => void,
  loginApi = new LoginApi({ exceptionLogger: sentryException }),
) : Promise<string | undefined> {
  const newExternalJwt = await getExternalJwt()

  // If the external JWT has not changed since this was last called, return
  // the token we've already converted
  if (newExternalJwt === cachedExternalJwt) {
    return user.getAccessToken()
  }
  let loginJwt = ''
  if (newExternalJwt) {
    const decodedExternalJwt = jwtDecode<Auth0Jwt>(newExternalJwt)
    if (!decodedExternalJwt?.email) {
      sentryException(new Error('Error: expected user JWT to include `email`'))
    }
    let clientId
    if (decodedExternalJwt.iss === 'https://test-grantstreet.auth0.com/') {
      clientId = 'sandbox-token-exchange'
    }
    // TODO: Remove hardcoded value and integrate with Site Settings API to grab
    // the issuer and the client ID.
    else if (decodedExternalJwt.iss === 'https://dev-atcsbcounty2.us.auth0.com/' || decodedExternalJwt.iss === 'https://authdev.sbcountyatc.gov/' || decodedExternalJwt.iss === 'https://authqa.sbcountyatc.gov/') {
      clientId = 'sbc-token-exchange'
    }
    else {
      sentryException(new Error(`Authorization is not yet set up for issuer '${decodedExternalJwt.iss}'`))
    }
    const response = await loginApi.exchangeToken({
      grantType: 'urn:ietf:params:oauth:grant-type:token-exchange',
      clientId,
      audience: 'https://pay-hub.net',
      scope: 'openid email profile',
      subjectToken: newExternalJwt,
      subjectTokenType: 'urn:ietf:params:oauth:token-type:id-token',
      requestedTokenType: 'urn:ietf:params:oauth:token-type:access-token',
    })
    if (!response?.data?.access_token) {
      throw new Error('Unexpected exchangeToken response format')
    }

    loginJwt = response.data.access_token
    // Saving this token is purely for e2e tests, so we
    // don't need to log an error if it fails.
    // We can just let the test fail.
    try {
      localStorage.setItem('loginSandboxToken', loginJwt)
    }
    catch {}

    const decodedLoginJwt = jwtDecode<LoginJwt>(loginJwt)
    updateUser(decodedExternalJwt, decodedLoginJwt)
  }
  return loginJwt
}

/**
 * A manager for the "external" login mode, which accepts a JWT from a parent
 * application instead of handling the full login redirect/callback process
 * itself (see ./full.ts).
 *
 * This class accepts either getAccessToken or getExternalJwt, not both.
 *
 * @param { Function } getAccessToken - Function that returns a user's JWT.
 * @param { Function } getExternalJwt - Asynchronous function that returns
 * a JWT from an external source. The login manager will token exchange
 * this JWT for a Login Service JWT.
 * @param { Object } [bus] - Special Vue 2 instance that helps transmit events.
 * So far, this is only supported for GovHub (optional).
 * @param { Function } [handleLogin] - Function that handles when a user attempts
 * to log into the site (optional).
 */
export default class ExternalLoginManager implements LoginManager {
  authPromise: AuthPromise
  // TypeScript seems to have some issues recognizing that resolveAuthPromise
  // gets set in authPromise.
  #resolveAuthPromise!: () => void
  #bus: Bus | undefined
  #getExternalJwt: (() => Promise<string>) | undefined
  #getAccessToken: (() => string) | undefined
  #handleLogin: (() => void) | undefined
  #loginApi: LoginApi

  constructor (opts: ExternalModeInstallOptions) {
    this.authPromise = new Promise(resolve => {
      this.#resolveAuthPromise = resolve
    })
    this.#bus = opts.bus
    this.#getExternalJwt = opts.getExternalJwt
    this.#getAccessToken = opts.getAccessToken
    this.#handleLogin = opts.handleLogin
    this.#loginApi = new LoginApi({ exceptionLogger: sentryException })
    this.#initLogin()
  }

  async #initLogin () {
    if (this.#getExternalJwt) {
      const loginJwt = await getJwt(this.#getExternalJwt, this.#updateUserFields, this.#loginApi)
      user.getAccessToken = () => loginJwt
      if (!loginJwt) {
        this.#clearUser()
        this.#bus?.$emit('login.userUnloaded', user)
      }
      else {
        this.#bus?.$emit('login.userLoaded', user)
      }
    }
    else if (this.#getAccessToken) {
      user.getAccessToken = this.#getAccessToken
    }
    this.#resolveAuthPromise()
  }

  #updateUserFields (externalJwt: Auth0Jwt, loginJwt: LoginJwt) {
    user.id = loginJwt.sub
    user.email = externalJwt.email
    user.name = externalJwt.name
    user.givenName = externalJwt.given_name
    user.familyName = externalJwt.family_name
    user.phone = externalJwt.phone
  }

  login: LoginFunction = ({
    callbackAction,
  }: LoginFunctionParams = {}) => {
    if (!this.#handleLogin) {
      sentryException(new Error('ExternalLoginManager requested login action without defining handleLogin'))
      return false
    }
    // Special directives and actions after login
    if (callbackAction) {
      this.#stashCallbackAction(callbackAction)
    }
    this.#bus?.$on('payhub.loaded', () => this.handleCallbackActions())

    this.#handleLogin()
  }

  logout: LogoutFunction = async () => {
    throw new Error('ExternalLoginManager does not support logout')
  }

  getPostLoginRedirect: GetPostLoginRedirectFunction = () => ''

  #clearUser () {
    user.reset()
  }

  #callbackActionsKey = 'callbackActions'

  #stashCallbackAction (action: CallbackActionType) {
    const actions = this.#unstashCallbackActions()
    storage.setItem(this.#callbackActionsKey, JSON.stringify([...actions, action]))
  }

  async handleCallbackActions () {
    await this.authPromise
    // Callback actions can't be handled without an EventBus. And there's nothing
    // to callback if the user isn't logged in.
    if (!this.#bus || !user.loggedIn) return

    const actions = this.#unstashCallbackActions()
    this.#bus.$emit('login.callbackActions', actions)
    for (const action of actions) {
      this.#bus.$emit('login.callbackAction', { user, action })
    }

    // Emitting this will update the language to the user's saved preference
    this.#bus.$emit('login.userLoaded', user)
  }

  #unstashCallbackActions () {
    let actions = storage.getItem(this.#callbackActionsKey)
    if (!actions) {
      return []
    }

    storage.removeItem(this.#callbackActionsKey)

    actions = JSON.parse(actions)
    if (!Array.isArray(actions)) {
      console.error(`Found unexpected "${this.#callbackActionsKey}" object in localStorage; ignoring`)
      return []
    }

    return actions
  }
}
